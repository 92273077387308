#map-openlayer-container {
  height: 800px;
  width: 100%;
  background-color: lightgrey;
  .color-legend {
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: white;
    padding: 10px;
    font-size: 12px;
  }
  /* Overall container styling */
  .date-selector {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    background: white;
    border: 1px solid #ccc;
    width: 400px;
  }

  /* Label styling */
  .date-selector .labels,
  .date-display {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px;
    font-size: 14px;
    color: #333;
    font-family: Arial, sans-serif;
    span {
      flex-shrink: 0;
    }
  }

  .date-display {
    justify-content: center;
    margin-bottom: 0;
  }
  /* Range input slider styling */
  .date-selector input[type="range"] {
    -webkit-appearance: none;
    width: 100%;
    height: 4px;
    background: black;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    margin-bottom: 8px;
  }

  /* Range input track styling */
  .date-selector input[type="range"]::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    height: 8px;
    background: transparent;
    border-radius: 4px;
  }

  /* Range input thumb styling */
  .date-selector input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 20px;
    width: 20px;
    background: white;
    border: 3px solid #3d3b7e;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    margin-top: -6px; /* Align thumb with track */
  }

  .date-selector input[type="range"]::-moz-range-thumb {
    height: 20px;
    width: 20px;
    background: white;
    border: 3px solid #3d3b7e;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .date-selector input[type="range"]::-ms-thumb {
    height: 20px;
    width: 20px;
    background: white;
    border: 3px solid #3d3b7e;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }

  .color-legend .scale {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .color-legend .scale-bar {
    width: 270px;
    height: 20px;
    margin-bottom: 5px;
  }

  .color-legend .scale-labels {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .color-legend .scale-labels span {
    font-size: 10px;
    color: #333;
  }

  .legend-container {
    display: flex;
    align-items: center;
    background-color: white;
    border: 1px solid #ddd;
    /* border-radius: 12px; */
    padding: 10px 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: 20px;
    right: 20px;
    background: white;
    padding: 10px;
    font-size: 12px;
    .legend-item {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
    .legend-item:last-child {
      margin-right: 0;
    }
    .legend-color {
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 8px;
      border: 1px solid #ccc;
    }
    .legend-text {
      font-size: 14px;
      color: #333;
    }
  }

  .ol-zoom {
    top: var(--spacing-ml);
    right: var(--spacing-ml);
    left: initial;
  }

  .info-overlay {
    position: absolute;
    background: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 12px;
  }

  .map-context-container {
    position: relative;
    width: 350px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 16px;
    line-height: 1.5;
    border: 1px solid #ddd;
    top: 140px;
    left: 23px;
    .message-title {
      font-size: 15px;
      font-weight: bold;
      margin-bottom: 8px;
    }

    .message-content {
      font-size: 14px;
      color: #555;
    }
  }
}
