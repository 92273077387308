/* Container */
.map-container {
  position: relative;
  .map-expandable-control {
    position: absolute;
    display: inline-block;
    top: var(--spacing-ml);
    left: var(--spacing-ml);
    z-index: 1;
  }

  /* Menu Toggle Button */
  .menu-toggle {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: var(--spacing-xs);
    padding: var(--spacing-s) var(--spacing-sl);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Menu Icon */
  .menu-icon {
    color: #666;
  }

  /* Control Panel */
  .control-panel {
    position: absolute;
    top: var(--spacing-ll);
    left: 0;
    background: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    padding: var(--spacing-s);
    z-index: 10;
    width: 655px;
  }

  .control-section {
    display: flex;
  }

  .control-toggle-view {
    align-content: center;
  }
  .control-divider {
    background-color: var(--color-light-grey);
    width: var(--spacing-xxs);
    margin: 0 var(--spacing-ml);
  }
  .control-selection-layers {
    .Select-selection-wrap {
      width: 250px;
    }
    .form_field > div:first-child {
      margin-bottom: 0px;
    }
  }
  /* Expanded State */
  .expandable-control.expanded .menu-toggle {
    background: #e6e6e6;
    border-color: #999;
  }
}
